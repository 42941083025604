import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import t from '@jetshop/intl';
import { Price } from '../Price';
import React from 'react';
import { styled } from 'linaria/react';
import CartItem from './CartItem';
import CloseButton from '../ui/CloseButton';
import DrawerHeader from '../ui/DrawerHeader';
import FreeShipping from './FreeShipping';
import { theme } from '../Theming/Theming';
import { ButtonUILinkExt } from '../ui/ButtonUI';

const Wrapper = styled('div')`
  background: white;
  color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  height: 100%;
  &.flyout {
    position: absolute;
    z-index: 999;
    background: white;
    right: 0;
    color: ${theme.colors.font};
    width: 420px;
    min-width: 19rem;
    max-width: 100%;
    border: 1px solid ${theme.colors.border};
  }
  .empty {
    margin: 0 1.5rem;
    padding: 0.5rem 0;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
  position: absolute;
  right: 3px;
  top: 30px;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  margin: 0 1.5rem;
  padding: 0.5rem 0;
  align-items: flex-start;
  justify-content: flex-end;
  h5 {
    font-size: 1.4rem;
  }
`;
const Summary = styled('section')`
  background: #fff;
  padding: 1rem 1.5rem;
  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  .discounts {
    padding: 5px;
    background: ${theme.colors.greys[0]};
    margin-bottom: 0.5rem;
  }
`;
const CartItems = styled('section')`
  padding: 1rem 1.5rem;
  overflow-y: auto;
`;

const TotalSummary = styled('div')`
  font-size: 1rem;
  color: ${theme.colors.font};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .price {
    font-weight: ${theme.weights.bold};
  }
`;

const SummaryTitle = styled('h3')`
  text-transform: none;
  font-size: 1rem;
  font-weight: ${theme.weights.bold};
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled(ButtonUILinkExt)`
  width: 100%;
  display: flex;
  a {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const CartContent = ({ result, modal, ...rest }) => {
  const cart = result?.data?.cart;
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const emptyCart = items.length === 0;

  return (
    <Wrapper {...rest}>
      <StyledDrawerHeader>
        <h5>{t('Your Cart')}</h5>
        <StyledCloseButton onClick={modal.hideTarget} />
      </StyledDrawerHeader>
      {emptyCart ? (
        <div className="empty">
          <p>{t('No items in cart.')}</p>
        </div>
      ) : (
        <>
          <CartItems>
            {items.map((item) => (
              <CartItem item={item} key={item.id} close={modal.hideTarget} />
            ))}
          </CartItems>
          <Summary>
            {discounts.length > 0 && (
              <div className="discounts">
                <h4>{t('Cart total')}</h4>
                <Flex>
                  <label>{t('Order value')}</label>
                  <Price price={cart.productPreviousTotal} />
                </Flex>
                <h4>{t('Discounts')}</h4>
                {discounts.map((discount) => {
                  return (
                    <Flex key={discount.name}>
                      <label>{discount.name}</label>
                      <Price
                        price={discount.value}
                        style={{ display: 'inline' }}
                      />
                    </Flex>
                  );
                })}
              </div>
            )}
            <TotalSummary>
              <SummaryTitle>{t('Total')}</SummaryTitle>
              <Price price={cart.productTotal} />
            </TotalSummary>
            <FreeShipping cartTotal={cart.productTotal} />
            {checkoutUrl && (
              <Checkout
                className="buy"
                href={checkoutUrl}
                onClick={(event) => {
                  event.preventDefault();
                  track(
                    trackCartCheckoutEvent({
                      cart: cart,
                      callback: () => {
                        window.location = checkoutUrl;
                      },
                    })
                  );
                }}
              >
                {t('Check out')}
              </Checkout>
            )}
          </Summary>
        </>
      )}
    </Wrapper>
  );
};

export default CartContent;

import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { Price } from '../Price';
import { theme } from '../Theming/Theming';

const Bar = styled('div')`
  position: relative;
  background: #f3efd7;
  height: 30px;
  margin-bottom: 1rem;
  &,
  .price {
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    .price {
      margin-right: 5px;
    }
  }
`;

const Progress = styled('div')`
  position: absolute;
  height: 100%;
  right: 0;
  background: ${theme.colors.beige};
`;

function FreeShipping({ cartTotal }) {
  const { hasMetLimit, untilLimit, freeShippingConfigured } =
    useFreeShippingCheck({
      cartTotal,
    });

  const freeShippingAmount = cartTotal?.incVat + untilLimit?.incVat;
  const percent = hasMetLimit
    ? 0
    : (untilLimit?.incVat / freeShippingAmount) * 100;

  if (!freeShippingConfigured) return null;

  return (
    <Bar>
      <div className="text">
        {hasMetLimit
          ? t('Free shipping!')
          : t.rich(`{price} to free shipping!`, {
              price: <Price key={1} price={untilLimit} />,
            })}
      </div>
      <Progress style={{ width: percent + '%' }} />
    </Bar>
  );
}

export default FreeShipping;

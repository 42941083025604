import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { filterListOnField, getColor } from './helpers';
import Image from '@jetshop/ui/Image';
import { useState } from 'react';
import { Color } from './Color';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    > li {
      text-align: center;
      width: auto;
      margin: 0 5px 5px;
      border: 2px solid ${theme.colors.secondary};
      overflow: hidden;
      > * {
        display: block;
      }
      &.image{
        width: 50px;
      }
      .color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        &.no-color-code {
          &:after {
            content: '';
            transform: rotate(45deg);
            position: absolute;
            height: 2px;
            top: calc(50% - 1px);
            width: 100%;
            background: #999;
            left: 0;
          }
        }
      }
    }
  }
  &:not(.grid){
    margin: 1rem 0;
    > ul {
      > li {
        position: relative;
        &.image{
          overflow: visible;
          width: 80px;
          margin: 0 5px 5px 0;
          padding: 5px;
          border: none;
          &.selected {
            border: ${theme.general.border};
          }
        }
      }
    }
  }
  &.grid {
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      right: 0;
      width: 50px;
      top: 0;
      content: '';
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 100%;
    }
    > ul {
      flex-wrap: nowrap;
      margin: 0 -2px;
      > li {
        margin: 2px;
        min-width: 12px;
        border: 1px solid ${theme.colors.borderLight};
        border-radius: 50%;
        padding: 1px;
        &.image{
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        &.selected {
          border-color: ${theme.colors.borderInput};
        }
        &.hovered {
          border-color: ${theme.colors.font};
        }
        .color {
          overflow: hidden;
          width: 10px;
          height: 10px;
          border-width: 1px;
          &.no-color-code {
            &:after {
              height: 1px;
              top: 3.6px;
            }
        }
      }
    }
  }
`;

export const SiblingButtons = ({
  productList,
  mainProduct,
  grid = false,
  setImage = false,
  setProduct = false,
}) => {
  const { colorTitle, colorList } = useContext(SiteContentContext);
  const [active, setActive] = useState(false);
  const list = !grid
    ? productList
    : filterListOnField(productList, mainProduct, 'size');
  return (
    <ButtonWrapper className={`sibling-button-wrapper ${grid ? 'grid' : ''}`}>
      {!grid && <VariantHeading>{colorTitle}</VariantHeading>}
      <ul>
        {list.map((product, index) => {
          const showColor = grid && colorList;
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const color = showColor && getColor(product);
          const colorCode = grid && color && colorList[color];
          const image =
            product.images && product.images.length > 0 && product.images[0];
          return (
            <li
              key={product.id}
              className={`${showColor ? 'hex-color' : 'image'} ${
                product.stockStatus?.buyable ? 'buyable' : 'not-buyable'
              } ${currentProduct ? 'selected' : ''} ${
                active === index ? 'hovered' : ''
              }`}
              onMouseEnter={() => {
                if (setImage && product?.images.length > 0) {
                  setImage(product.images[0]);
                  setActive(index);
                }
              }}
              onClick={() => {}}
            >
              <Tag
                product={product}
                onClick={(e) => {
                  if (setProduct) {
                    setProduct(product);
                    e.preventDefault();
                  }
                }}
              >
                {showColor ? (
                  <Color
                    color={colorCode}
                    className={`color ${colorCode ? '' : 'no-color-code'}`}
                  />
                ) : (
                  <>
                    {image && (
                      <Image
                        sizes={grid ? [20] : [100]}
                        aspect={theme.productGrid.product.imageAspect}
                        crop={false}
                        src={image.url}
                        alt={image.alt}
                        modifiedDate={image.modifiedDate}
                      />
                    )}
                  </>
                )}
              </Tag>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import Logo from '../Header/Logo';
import { LoginWidget } from '../Header/Util/LoginWidget';
import { getFontSize } from '../../Theming/helpers';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0;
    color: ${theme.footer.color};
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .logo-wrapper {
    max-width: 280px;
    padding: 0 2rem;
  }
  > div {
    > div {
      padding: 2rem;
      flex-grow: 1;
    }
  }
  ${theme.above.md} {
    flex-direction: row;
    .left {
      padding-right: 3rem;
      width: 40%;
      > div {
        padding-top: 0;
      }
    }
    .right {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  h3 {
    margin-bottom: 1rem;
    font-weight: ${theme.weights.normal};
    font-family: ${theme.font.secondary};
  }
  ul {
    li {
      line-height: 2em;
    }
  }
  .login {
    ul {
      li {
        line-height: 1.6;
        font-weight: ${theme.weights.bold};
        ${getFontSize(theme.typography.header.fontSize[3])}
      }
    }
  }
`;

export const FooterLogo = styled('div')`
  width: 100%;
  > div {
    width: 100%;
    max-width: 600px;
  }
`;

const Footer = () => {
  const { footer1, footer2, footer3, footer4, footerLogo } =
    useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              <div className="left">
                <div className="logo-wrapper">
                  <Logo />
                </div>
                <div>{footer1}</div>
              </div>
              <div className="right">
                <div>{footer2}</div>
                <div>{footer3}</div>
                <div>{footer4}</div>
                <div className="login">
                  <LoginWidget />
                </div>
                {footerLogo && (
                  <FooterLogo>
                    <div>
                      <Image aspect="6:1" src={footerLogo} />
                    </div>
                  </FooterLogo>
                )}
              </div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;

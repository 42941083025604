import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router-dom';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { SiblingButtons } from './SiblingButtons';
import { useSiblingProducts } from './useSiblingProducts';
import { SiblingSizeButtons } from './SiblingSizeButtons';

const Wrapper = styled('div')`
  width: 100%;
  &:not(.grid) {
    ${ProductInfoMarginBottom};
  }
`;

const SiblingProducts = ({ product, grid, setImage, setProduct }) => {
  const productList = useSiblingProducts({ product });
  if (!productList) return null;
  return (
    <Wrapper className={`${grid ? 'grid' : ''} sibling-products`}>
      <SiblingButtons
        productList={productList}
        mainProduct={product}
        grid={grid}
        setImage={setImage}
        setProduct={setProduct}
      />
      {!grid && (
        <SiblingSizeButtons productList={productList} mainProduct={product} />
      )}
    </Wrapper>
  );
};

export default withRouter(SiblingProducts);

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from './NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { MaxWidthWrapper, Inner } from '../../MaxWidth';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-top: ${theme.general.border};
  .image-wrapper {
    width: 100%;
    [data-flight-image-container] {
      ${theme.below.lg} {
        padding-bottom: 50% !important;
      }
      ${theme.below.md} {
        padding-bottom: 80% !important;
      }
      ${theme.below.sm} {
        padding-bottom: 110% !important;
      }
      @media (min-width: 120rem) {
        padding-bottom: 17% !important;
      }
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.md} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    .text {
      > h1,
      > h2,
      > h3,
      > h4,
      > h5 {
        font-family: ${theme.font.secondary};
      }
      > h2,
      > h3,
      > h4,
      > h5,
      > p {
        margin: 0 0 0.5em;
      }
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 16px;
      }
    }
    > * {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
    .newsletter-wrapper {
      input {
        background: ${theme.colors.white};
        border: none;
        margin-right: 1rem;
        border: 1px solid #ddd;
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.font};
        font-weight: ${theme.weights.bold};
        opacity: 1 !important;
        border: none;
        border: ${theme.general.border};
      }
    }
  }

  &.standard {
    border-top: ${theme.general.border};
    background: ${theme.footer.background};
    border-bottom: 1px solid white;
    .text-wrapper {
      max-width: 800px;
      margin: 0 auto;
      .form-wrapper {
        margin-top: 1rem;
        > p {
          text-align: center;
        }
      }
    }
  }

  //TRANSPARENT TYPE
  &.transparent {
    ${MaxWidthWrapper} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
    }
    > h2,
    > h3,
    > h4,
    > h5,
    > p {
      color: ${theme.colors.white};
    }
    .error {
      color: ${theme.colors.white};
    }
    .text-wrapper {
      background: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      h2,
      h3,
      h4,
      h5,
      p {
        color: ${theme.colors.white};
      }
      .text {
        h5 {
          margin: 2rem 0;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const { showNewsletter, newsletter, newsletterImage } =
    useContext(SiteContentContext);

  if (!showNewsletter) return null;

  return (
    <NewsletterBoxWrapper
      className={newsletterImage ? 'transparent' : 'standard'}
    >
      {newsletterImage && (
        <div className="image-wrapper">
          <Image
            aspect={'5:1'}
            sizes={[1, 1, 1, 1]}
            src={newsletterImage}
            alt={newsletterImage}
            crop={true}
            cover
          />
        </div>
      )}
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">
            <div className="text">{newsletter}</div>
            <NewsletterField />
          </div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;

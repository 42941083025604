import bodyReg from './Pitch-Regular.woff2';
import bodyBold from './Pitch-Bold.woff2';
import headerReg from './Sohne.woff2';
import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'pitch';
        src: url(${bodyReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pitch';
        src: url(${bodyBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'sohne';
        src: url(${headerReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}

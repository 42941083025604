import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import SearchIcon from '../../../ui/icons/SearchIcon';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';
import { theme } from '../../../Theming/Theming';

export const headerTransitions = `
  transition: all 0.5s ease;
`;

const DesktopHeaderWrapper = styled('div')`
  height: ${theme.desktopHeader.height.lg + 'px'};
  ${headerTransitions}
  padding: 0 0;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.height.scroll + 'px'};
    box-shadow: 0px 1px 5px #0000001c;
  }
  background: ${theme.desktopHeader.background};
  .page-padding,
  .inner {
    height: 100%;
  }
  .inner {
    display: flex;
    align-items: center;
  }

  .top-nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > li {
      padding: 0 0.6rem;
      display: flex;
      height: 100%;
      align-items: center;
      &:last-child {
        padding-right: 0.6rem !important;
      }
    }
  }
  .header-icon {
    font-size: 22px;
    color: ${theme.desktopHeader.color};
  }
  .header-button {
    position: relative;
    display: flex;
  }
`;

const StoreLogo = styled('div')`
  transition: height 0.4s ease;
  width: 240px;
  transition: width 0.4s ease;
  //margin: 0 auto;
  ${theme.only.md} {
    max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
  }
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
    > img,
    > div,
    > svg {
      transition: max-height 0.4s ease;
      max-width: 100%;
      max-height: ${theme.desktopHeader.height.lg - 20 + 'px'};
      ${theme.only.md} {
        max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
      }
    }
  }
  &[data-scrolling='true'] {
    max-height: ${theme.desktopHeader.height.scroll + 'px'};
    a {
      > img,
      > div,
      > svg,
      > .scroll-logo {
        max-height: ${theme.desktopHeader.height.scroll - 20 + 'px'};
      }
    }
    .scroll-logo {
    }
  }
`;

export const HeaderButton = styled('button')`
  display: flex;
`;

export const HeaderIconText = styled('span')`
  display: none;
  ${theme.below.lg} {
    display: none;
  }
`;

const SearchButtonToggle = styled('button')``;

const SearchFieldWrapper = styled('div')`
  position: relative;
  .masking {
    position: absolute;
    width: 200px;
    height: 46px;
    left: -537px;
    top: -22px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .search-wrapper {
    border: 2px solid ${theme.colors.primary};
    border-radius: 4px;
    position: absolute;
    width: 340px;
    top: -22px;
    right: 0;
    padding-right: 0;
    z-index: 1;
  }
  input {
  }
  .submit-search {
    right: 3rem;
  }
`;

const DesktopHeader = ({ scrolling, searchOpen, setSearch, children }) => {
  return (
    <DesktopHeaderWrapper data-scrolling={scrolling}>
      <PagePadding>
        <Inner className="inner">
          <StoreLogo data-scrolling={scrolling}>
            <Link to="/">
              <Logo type="desktop" />
            </Link>
          </StoreLogo>
          {children}
          <TopNav right>
            {searchOpen ? (
              <SearchFieldWrapper data-scrolling={scrolling}>
                <div className="masking" />
                <SearchField
                  onCancel={() => {
                    setSearch(false);
                  }}
                  setSearch={setSearch}
                  searchOpen={searchOpen}
                  scrolling={scrolling}
                />
              </SearchFieldWrapper>
            ) : (
              <SearchButtonToggle
                href="#"
                onClick={() => setSearch(true)}
                className="header-button"
              >
                <SearchIcon className="header-icon" />
              </SearchButtonToggle>
            )}
            <FavouriteCount className="header-button" />
            <CartButton className="header-button" />
          </TopNav>
        </Inner>
      </PagePadding>
    </DesktopHeaderWrapper>
  );
};

export default DesktopHeader;
